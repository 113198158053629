export const Logo = (
  <svg
    // width="172"
    height="50"
    viewBox="0 0 172 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        className="fill-white group-hover:fill-[#FB6970] duration-300"
        d="M41.36 63.1578C56.2448 63.1578 67.75 51.8471 67.75 37.8948C67.75 24.8235 56.9414 16.8422 49.7823 16.8422C39.8098 16.8422 31.8172 23.8838 30.6834 34.3358C30.4743 36.2641 28.9455 37.8806 27.006 37.8666C23.7943 37.8435 19.1329 37.8161 16.1771 37.8262C14.8993 37.8306 13.8427 36.794 13.9054 35.5177C14.3506 26.4558 17.3623 17.9827 23.1065 11.4741C29.4878 4.24386 38.7805 0 49.7823 0C68.267 0 85.717 16.8931 85.717 37.8948C85.717 61.1489 66.561 80 41.7534 80C24.0614 80 6.24284 68.3708 0.0216444 52.1126C-0.00135561 52.0524 -0.00515854 51.9894 0.00614146 51.9241C0.0888415 51.443 0.253443 51.0035 0.415443 50.5706C0.651043 49.9416 0.881241 49.3266 0.847641 48.6177L0.740143 46.353C0.687143 45.2358 1.31464 44.1969 2.32984 43.7216L3.22914 43.3005C4.25454 42.8204 4.98354 41.8748 5.18524 40.7631C5.48664 39.1023 6.93634 37.8948 8.62844 37.8948C11.2696 37.8948 13.566 39.6525 14.4231 42.1508C18.5769 54.2572 25.1791 63.1578 41.36 63.1578Z"
        transform="scale(2)"
      />
    </g>
  </svg>
)
